<div class="loader-container">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="
      margin: auto;
      background: rgb(255, 255, 255);
      display: block;
      shape-rendering: auto;
    "
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle cx="50" cy="22" r="12" class="ball-color">
      <animate
        attributeName="cy"
        dur="1s"
        repeatCount="indefinite"
        calcMode="spline"
        keySplines="0.45 0 0.9 0.55;0 0.45 0.55 0.9"
        keyTimes="0;0.5;1"
        values="22;78;22"
      />
    </circle>
    <linearGradient id="myGradient">
      <stop class="main-stop" offset="0%" />
      <stop class="alt-stop" offset="100%" />
    </linearGradient>
  </svg>
</div>
