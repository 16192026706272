<button class="custom-wide-button custom-button" @fadeAppearBottomTop [ngClass]="{
    'primary-button-custom': theme === ButtonTheme.primary,
    'secondary-button-custom': theme === ButtonTheme.secondary
  }" mat-raised-button (click)="onClickButton()" *ngIf="displayDetails" [type]="displayDetails.type"
  [disabled]="disabled">
  <span class="start-content">
    <mat-icon *ngIf="displayDetails.icon" fontSet="material-icons-round">{{
      displayDetails.icon
      }}</mat-icon>
    <span class="label" [innerHtml]="displayDetails.label">
    </span>
  </span>
  <mat-icon fontSet="material-icons-round" *ngIf="displayDetails.secondaryIcon">{{
    displayDetails.secondaryIcon
    }}</mat-icon>
</button>
