<div class="logo-container">
  <a class="logo" routerLink="/">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1"
      viewBox="0 0 899 321.9">
      <defs>
        <style>
          .cls-1 {
            fill: url(#linear-gradient);
          }

          .cls-1,
          .cls-2 {
            stroke-width: 0px;
          }

          .cls-2 {
            fill: #fff;
          }
        </style>
        <linearGradient id="linear-gradient" x1="0" y1="160.95" x2="899" y2="160.95" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4a00e0" />
          <stop offset="1" stop-color="#0054ff" />
        </linearGradient>
      </defs>
      <rect class="cls-1" width="899" height="321.9" rx="40" ry="40" />
      <path class="cls-2"
        d="m214.78,173.39c0,14.43-7.93,25.35-35.36,25.35h-76.44v-52.91h76.96c3.9,0,7.54-1.43,7.54-5.85s-3.77-5.59-7.54-5.59h-76.96l18.2-22.62h57.59c20.8,0,34.19,8.06,34.19,24.05,0,10.27-5.85,15.99-11.83,18.2,7.54,2.21,13.65,8.97,13.65,19.37Zm-84.76-8.97v11.96h49.53c5.85,0,8.32-2.08,8.32-6.11,0-3.77-2.47-5.85-8.32-5.85h-49.53Z" />
      <path class="cls-2"
        d="m257.29,198.74l16.25-22.36h21.84c5.2,0,11.31,0,15.47.26-2.34-3.12-5.98-8.45-8.84-12.61l-15.6-23.01-40.82,57.72h-31.85l55.77-78.65c3.64-5.07,8.97-9.75,17.42-9.75s13.39,4.29,17.16,9.75l54.34,78.65h-101.14Z" />
      <path class="cls-2" d="m462.3,176.38l-17.42,22.36h-76.83v-86.97h26.91v64.61h67.34Z" />
      <path class="cls-2" d="m565.51,176.38l-17.42,22.36h-76.83v-86.97h26.91v64.61h67.34Z" />
      <path class="cls-2"
        d="m665.87,198.74h-82.42c-6.76,0-12.48-3.9-12.48-10.92,0-4.03,2.21-7.93,5.85-10.79l31.72-24.96c14.43-11.57,20.67-15.86,23.92-18.2-4.68.39-12.61.52-29.9.52h-34.84l17.16-22.62h80.21c6.76,0,12.48,3.9,12.48,10.92,0,3.9-2.21,7.93-5.85,10.66l-32.11,25.48c-14.3,11.31-20.28,15.73-23.66,18.07,4.29-.26,20.93-.52,34.58-.52h31.59l-16.25,22.36Z" />
      <path class="cls-2"
        d="m815.76,155.19c0,31.07-21.32,44.98-63.57,44.98s-63.57-13.91-63.57-44.98,21.19-44.85,63.57-44.85,63.57,13.78,63.57,44.85Zm-26.78,0c0-18.72-9.75-22.23-36.79-22.23s-36.79,3.51-36.79,22.23,9.75,22.36,36.79,22.36,36.79-3.64,36.79-22.36Z" />
    </svg>
  </a>
</div>